import { Routes, Route, BrowserRouter } from "react-router-dom";
import { lazy, Suspense } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./components/Loading";

const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));
const Home = lazy(() => import("./components/Home"));
const Rules = lazy(() => import("./components/Rules"));
const Sponsors = lazy(() => import("./components/Sponsors"));
const AboutUs = lazy(() => import("./components/AboutUs"));
const PageRanks = lazy(() => import("./pages/PageRanks"));
const PageTournaments = lazy(() => import("./pages/PageTournaments"));
const PageComingSoon = lazy(() => import("./pages/PageComingSoon"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const PageContactUs = lazy(() => import("./pages/PageContactUs"));
const PageViewTournament = lazy(() => import("./pages/PageViewTournamet"));
const AdToLeader = lazy(() => import("./components/AdToLeader"));
AOS.init({
  duration: 600,
  offset: 100,
});
function App() {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/sponsors" element={<Sponsors />} />
            <Route path="/about_us" element={<AboutUs />} />
            <Route path="/ranking" element={<PageRanks />} />
            <Route path="/tournaments" element={<PageTournaments />} />
            <Route path="/tournament_:id" element={<PageViewTournament />} />
            <Route path="/streamers" element={<PageComingSoon />} />
            <Route path="/news" element={<PageComingSoon />} />
            <Route path="/store" element={<PageComingSoon />} />
            <Route path="/contact_us" element={<PageContactUs />} />
            <Route path="/add_to_leader" element={<AdToLeader />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
