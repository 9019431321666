import React from "react";
import img_url from "../assests/call-of-duty-black-ops-skull-logo.png";
import eye_url from "../assests/eye.png";
import "./loading.css";
const Loading = () => {
  return (
    <>
      <div id="loading_div">
        <div id="loading_img_div">
          <img id="loading_img" src={img_url} alt="" srcset="" />
          <img id="loading_img_eye" src={eye_url} alt="" srcset="" />
        </div>

        <h4>Loading...</h4>
      </div>
    </>
  );
};

export default Loading;
